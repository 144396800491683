<!--
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2022-12-30 09:24:55
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-02-15 10:41:52
 * @FilePath: /com.huizhouyiren.B0805/src/views/index/pages/navbarPages/Community.vue
 * @Description: 社区主页
-->
<template>
    <div class="w-full content_page" style="background: #F6F6F6;">
        <div class="flex justify-center w-full">
            <div class="text-left text-gray-500 path sm:py-4 conent_box"><navbar-text
                    :navbarList="navbarList"></navbar-text></div>
        </div>
        <div class="flex justify-center w-full pt-4">
            <div class="conent_box">
                <div class="flex justify-between pb-4 item" v-for="item in community" :key="item.id">
                    <div>
                        <div class="bg-gray-300 rounded img_box">
                            <img :src="item.picUrl" alt style="width:100%;height:auto;object-fit:cover" />
                        </div>
                        <div class="mt-1 tilte">{{ item.communityName }}</div>
                    </div>
                    <div style="width:calc(100% - 8.75rem);overflow: hidden;" class="text-left">
                        <div>{{ item.description }}</div>
                        <div class="mt-2 cursor-pointer text-primary">
                            <span @click="goPage(item.id)">详情>></span>
                        </div>
                        <div class="mt-2 cursor-pointer">
                            <span>链接：</span>
                            <span class="text-primary" @click="open(item.link)">点击进入</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            community: [],
            navbarList: [{ title: "社区", path: "" }]
        };
    },
    computed: {
        ...mapGetters(['getLoginStatus', 'getUserInfo'])
    },
    created() {
        this.getCommunity()
    },
    methods: {
        // 获取社区
        getCommunity() {
            this.$request({
                methods: "get",
                url: this.$requestPath.getCommunity,
                params: {}
            }).then(res => {
                console.log('获取社区', res)
                if (res.code == 0) {
                    // this.productList = []
                    // this.productList.push(...res.data)
                    // console.log(this.productList)
                    this.community = []
                    this.community.push(...res.data)
                    // if (this.community.length == 0) {
                    //     this.$EventBus.$emit("toast", { type: 'warning', message: '暂无相关社区数据' })
                    //     return
                    // }
                    // this.showCommunity = true
                }
            }).catch(error => {
                console.log("获取的海报", error)
            })
        },

        goPage(id) {
            this.$router.push({ path: "/index/communityinfo", query: { id } })
        },
        open(url) {
            window.open(url)
        }

    },
};
</script>

<style lang='scss' scoped>
.content_page {
    min-height: var(--min-height);
}

.conent_box {
    width: 100%;
    max-width: 75rem;
    padding-left: var(--margin-base);
    padding-right: var(--margin-base);
}

.img_box {
    height: 7.5rem;
    width: 7.5rem;
}
</style>